import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout/Layout';
import Content, { HTMLContent } from '../components/layout/Content';
import SEO from '../components/seo/SEO';

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  featuredimage,
  date,
  helmet,
}) => {
  const PostContent = contentComponent || Content;
  return (
    <Fragment>
      <section className='section'>
        {helmet || ''}
        <div className='py-5 bg-white shadow-2xl rounded-3xl'>
          <div className='lg:max-w-7xl mx-auto px-4 mt-4'>
            <div className='px-6 py-6 text-center'>
              <h1 className='text-lg text-yellow-700 font-semibold tracking-wide uppercase'>
                blog
              </h1>
              <h1 className='mt-2 text-6xl font-extrabold tracking-tight text-gray-700'>
                {title}
              </h1>
              <h1 className='mt-2 text-4xl font-bold tracking-tight text-gray-700'>
                {description}
              </h1>
              <h1 className='mt-2 mb-4 max-w-2xl mx-auto text-2xl font-normal text-yellow-700'>
                Hondo Lane | {date}
              </h1>
              <div className='text-justify text-gray-700'>
                <PostContent content={content} />
                {tags && tags.length ? (
                  <div className='mt-4'>
                    <h4 className='text-lg text-yellow-700 font-semibold tracking-wide uppercase'>
                      Tags
                    </h4>
                    <ul className='taglist'>
                      {tags.map((tag) => (
                        <li
                          className='mt-2 text-xl font-extrabold tracking-tight text-gray-700 hover:text-yellow-700'
                          key={tag + `tag`}
                        >
                          <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={post.frontmatter.featuredimage}
        pathname={`/blog/${post.fields.slug}`}
      />
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate='%s | Blog'>
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name='description'
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 120, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
